import React from "react";
import { Section } from "src/Components/Common/Section";
import LyviaImg from "src/assets/Lyvia.jpg";

export const Team = () => {
  return (
    <div className={"About"}>
      <Section
        title={"Lyvia Chriki, Ph.D."}
        subheading={
          <>
            <div>Clinical Psychologist</div>
            <div>Founder of Boston Evidence Supported Treatments</div>
          </>
        }
        headerImage={<img src={LyviaImg} />}
      >
        <div>
          I prefer to be straightforward, focusing on helping clients achieve
          behavioral change towards living a life that is in line with their
          values and priorities. I have been trained in a variety of treatment
          modalities and work from within an acceptance based cognitive behavior
          therapy framework, helping clients learn to balance the acceptance of
          their own experiences with behavior change by focusing on their
          values, strengths, and learning to build more self- and
          general-awareness.
        </div>
        <div className="breaker"></div>
        <div className={"buffer small"}>
          I am not always peppy and don't expect you to be.
        </div>
        <div className={"buffer small"}>
          Sessions can be fulfilling, draining, grueling, or quietly
          overwhelming. Sometimes a single session is all of these.
        </div>
        <div className={"buffer"}>
          What I do expect is that you have reached a point where you want to
          make changes; a point where you've decided you don't want to keep on
          the same path. Or maybe you've realized an issue before it's
          overwhelming you and want to deal with it now.
        </div>
        <div className={"buffer small"}>
          We'll work hard. I'll be there to listen, guide, teach, and keep you
          going. You'll have the hard job of processing emotions, implementing
          changes to your lifestyle and outlook, and continuing on.
        </div>
        <div className="gap buffer small"></div>
        <div>
          <div style={{ fontSize: "24px", lineHeight: "34px" }}>
            Areas of Expertise:
          </div>
          <div style={{ fontStyle: "italic" }}>
            My specialties include treating adolescents, young adults, and
            adults. I work both individually or with families, depending on what
            is needed, and I value involving families when working with teens. I
            enjoy helping those struggling to build independence, manage intense
            emotions, build meaningful relationships, and work towards life
            goals. I am highly trained in providing treatment for individuals
            who have had traumatic experiences and am intensively trained in
            Dialectical Behavior Therapy (DBT), evidence based treatments for
            PTSD (PE, CPT, DBT-PE, and DBT-PTSD) and exposure based treatments
            (ExRP and ACT). Finally, specialize in providing consultation and
            guidance to parents of teens who are struggling.{" "}
          </div>
        </div>
      </Section>
    </div>
  );
};
