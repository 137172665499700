import React from "react";
import { Section } from "src/Components/Common/Section";
import { ConnectingLink } from "./ConnectingLink";

export const ConnectingLanding = () => {
  return (
    <div className={"ConnectingLanding"}>
      <div className={"content"}>
        <Section title={"Connect"}>
          Looking for an event you can't find? Curious if we'll be teaching a
          specific topic soon? We're happy to answer!
          <ConnectingLink />
        </Section>
      </div>
    </div>
  );
};
