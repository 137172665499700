import React from "react";
import { Section } from "../Common/Section";

export const OtherPage = () => {
  return (
    <div className="OtherPage">
      <div className={"alert"}>Due to covid...</div>
      <br />
      <br />
      <br />
      <div>
        Coming out of a week of strong training feels so energizing. We love
        coming into sessions with more knowledge, armed with new skills to help
        our patients heal or an alternative vantage point to see their
        struggles. Sometimes it's as small as having that one new line we picked
        up and watching how powerful it can be, but even better is a week of new
        techniques in a subject that was outside our comfort zone before.
      </div>
      <br />
      <br />
      <div>
        Training is how we stay sharp and up-to-date on the science of mental
        and emotional health. We're thrilled to offer new avenues for that
        growth to the field.
      </div>
      <br />
      <br />
      <div className="imageHolder"></div>
      <img src="https://lh3.googleusercontent.com/proxy/AwDMrs93KvwhSI-zD1xalYCY_oW3DAbIP_FzEbp9MEn0cREv7VDlXBYzn3MbymGz6cR1Ca6NbONRBhduRA9TaZY8SnHNG6I" />
    </div>
  );
};
