import React from "react";
import { Section } from "src/Components/Common/Section";

export const TreatmentLanding = () => {
  return (
    <div className={"TreatmentLanding"}>
      <div className={"content"}>
        <Section title={"Treatment"}>
          The work is collaborative.
          <br />
          <br /> We provide a variety of treatments, all empirically based,
          meaning they have been shown to be effective through research and are
          based on scientific data. It's important to us to stay tuned to the
          most recent evidence and treatments so we provide the best care we can
          give. Simultaneously, while treatments are based on evidence, we don’t
          lose sight of the individual, the context in which the person lives
          and has grown up, and the goals the person is looking to meet in
          therapy.
          <div
            className="semibold"
            style={{
              padding: "15px 10px",
              margin: "20px 0 0",
              background: "white",
            }}
          >
            You are the expert on your experiences. We have knowledge and
            expertise on changing. Together we help you move in new directions.
          </div>
        </Section>
        {/*} SOME IMAGE OF LIKE COLORFUL DANCERS THAT ARE MOVING AWAY AND ALSO
        COMBINED. Sort of like the middle-to-end of this video:{" "}
        <a href="https://youtu.be/fsW1z9QThsA" target="_blank">
          Video
        </a>
        <br />
        <br />A list like: "Specific Treatments Provided" and the boxes with
        names (or just the names big and bold) and they are links to the speicic
        pages, or maybe they stay in this section and we just change the text
          above?*/}
      </div>
    </div>
  );
};
