import React from "react";

export const ConnectingLink = ({
  altEmail,
  altBody,
}: {
  altEmail?: string;
  altBody?: string;
}) => {
  return (
    <a
      className={`ConnectingLink`}
      title={`Email ${altEmail ?? "info@epbne.com"}`}
      href={`mailto:${altEmail ?? "info@epbne.com"}`}
    >
      {altBody ?? "FILLIN PHRASE FOR CONTACTING"}
    </a>
  );
};
