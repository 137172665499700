import React from "react";
import { Section } from "src/Components/Common/Section";

export const CPT = () => {
  return (
    <div className={"content"}>
      <Section title={"Cognitive Processing Therapy (CPT)"}>
        CPT also helps patients confont their past rather than work to avoid it,
        but instead of the primary focus being on approaching the memory and
        situations that are being avoided, the focus is on identifying the
        cognitions, or beliefs and thoughts, that grew out of the traumatic
        experience and are keeping the person stuck in the past. People with
        PTSD often come out of the traumatic experience with thoughts that it is
        their fault or that people can’t be trusted, for example. In CPT, we
        will work on loosening up how attached you are to these thoughts and
        help you think more flexibly so as to move forward from what happened.
        CPT has also shown to be very effective.
      </Section>
    </div>
  );
};
