import React from "react";
import { Section } from "src/Components/Common/Section";

export const CBT = () => {
  return (
    <div className={"content"}>
      <Section title={"Cognitive Behavior Therapy (CBT)"}>
        CBT is a treatment approach that helps people become more aware of the
        relationship among their internal experiences (emotions, thoughts,
        sensations) and behaviors, and learn how to change how they respond to
        their experiences and begin acting in ways that are consistent with
        their goals and values. CBT is an umbrella term that encapsulates many
        types of treatments for different conditions or contexts.
      </Section>
    </div>
  );
};
