import React from "react";
import { useLocation } from "react-router";
import { Section } from "./Common/Section";
import { ConnectingLink } from "./Features/Connecting/ConnectingLink";

export const FourOhFour = () => {
  const routerLocation = useLocation();

  return (
    <Section title={"Location not found..."}>
      Oh dear! We tried finding {routerLocation.pathname}, but can't locate it
      anywhere. If there should have been something here, please{" "}
      <ConnectingLink />. Otherwise, the menu above should take you wherever you
      need to go.
    </Section>
  );
};
