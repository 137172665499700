import React from "react";
import { HoverDrop } from "./Common/HoverDrop";
import { InternalLink } from "./Common/InternalLink";

export const MainMenu = () => {
  return (
    <div className={"MainMenu"}>
      <div className={"leftMenu"}>
        <div className={"titleHolder"}>
          <InternalLink to="/" classname={"title"} color={"black"}>
            <title>
              <span>B</span>oston <span>E</span>vidence <span>S</span>upported{" "}
              <span>T</span>reatments
            </title>
          </InternalLink>
        </div>
      </div>

      <div className={"rightMenu"}>
        <HoverDrop
          classname={"item"}
          mainDisplay={<InternalLink to={"/about/"}>Us</InternalLink>}
          insideDrop={
            <>
              <div>
                <InternalLink to={"/about/"} classname={" textRight"}>
                  Who We Are
                </InternalLink>
              </div>
              {/*<div>
                <InternalLink to={"/about/office/"} classname={" textRight"}>
                  Office
                </InternalLink>
              </div>*/}
              <div>
                <InternalLink to={"/about/team/"} classname={" textRight"}>
                  The Team
                </InternalLink>
              </div>
            </>
          }
          goRight={true}
        />
        <HoverDrop
          classname={"item"}
          mainDisplay={
            <InternalLink to={"/treatment/"}>Treatment</InternalLink>
          }
          insideDrop={
            <>
              <div>
                <InternalLink to={"/treatment/"} classname={" textRight"}>
                  Our Approach
                </InternalLink>
              </div>
              <div>
                <InternalLink to={"/treatment/act/"} classname={" textRight"}>
                  ACT
                </InternalLink>
              </div>
              <div>
                <InternalLink to={"/treatment/cbt/"} classname={" textRight"}>
                  CBT
                </InternalLink>
              </div>
              <div>
                <InternalLink to={"/treatment/dbt/"} classname={" textRight"}>
                  DBT
                </InternalLink>
              </div>
              <div>
                <InternalLink to={"/treatment/dbt-a/"} classname={" textRight"}>
                  DBT-A
                </InternalLink>
              </div>
              <div>
                <InternalLink
                  to={"/treatment/exposure/"}
                  classname={" textRight"}
                >
                  Exposure
                </InternalLink>
              </div>
              <div>
                <InternalLink
                  to={"/treatment/ro-dbt/"}
                  classname={" textRight"}
                >
                  RO-DBT
                </InternalLink>
              </div>
              <div className="innerSection">
                <div className="title">Trauma Work</div>
                <div>
                  <InternalLink
                    to={"/treatment/trauma/cpt/"}
                    classname={" textRight"}
                  >
                    CPT
                  </InternalLink>
                </div>
                <div>
                  <InternalLink
                    to={"/treatment/trauma/peptsd/"}
                    classname={" textRight"}
                  >
                    PE for PTSD
                  </InternalLink>
                </div>
                <div>
                  <InternalLink
                    to={"/treatment/trauma/dbtpe/"}
                    classname={" textRight"}
                  >
                    DBT-PE
                  </InternalLink>
                </div>
                <div>
                  <InternalLink
                    to={"/treatment/trauma/dbtptsd/"}
                    classname={" textRight"}
                  >
                    DBT-PTSD
                  </InternalLink>
                </div>
              </div>
              <div>
                <InternalLink
                  to={"/treatment/parent-guidance/"}
                  classname={" textRight"}
                >
                  Parent Guidance
                </InternalLink>
              </div>
            </>
          }
          goRight={true}
        />
        <InternalLink to={"/consultation/"} classname={"item"}>
          Consultation
        </InternalLink>

        <HoverDrop
          classname={"item"}
          mainDisplay={
            <InternalLink to={"/getting-started/"}>New Clients</InternalLink>
          }
          insideDrop={
            <>
              {/*<div>
                <InternalLink to={"/getting-started/"} classname={" textRight"}>
                  Initial Concerns
                </InternalLink>
              </div>
              <div>
                <InternalLink
                  to={"/getting-started/forms/"}
                  classname={" textRight"}
                >
                  Forms
                </InternalLink>
              </div>*/}
              <div>
                <InternalLink
                  to={"/getting-started/policies/"}
                  classname={" textRight"}
                >
                  Polices & Fees
                </InternalLink>
              </div>
            </>
          }
          goRight={true}
        />
        <HoverDrop
          classname={"item"}
          mainDisplay={<InternalLink to={"/contact/"}>Contact</InternalLink>}
          insideDrop={
            <>
              <div>
                <InternalLink to={"/contact/"} classname={" textRight"}>
                  Communicate
                </InternalLink>
              </div>
              <div>
                <InternalLink
                  to={"/contact/location/"}
                  classname={" textRight"}
                >
                  Location
                </InternalLink>
              </div>
            </>
          }
          goRight={true}
        />
      </div>
    </div>
  );
};
