import React from "react";
import "./Styles/App.scss";
import { MainMenu } from "./Components/MainMenu";
import { MainContent } from "./Components/MainContent";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="GroupPractice">
      <BrowserRouter>
        <MainMenu />

        <MainContent />
      </BrowserRouter>
    </div>
  );
}

export default App;
