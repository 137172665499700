import React from "react";
import { Lister } from "src/Components/Common/Lister";
import { Section } from "src/Components/Common/Section";

export const DBT = () => {
  return (
    <div className={"content"}>
      <Section title={"Dialectical Behavior Therapy (DBT)"}>
        DBT is a treatment that was initially developed for suicidal individuals
        who experience emotions very intensely and have difficulty managing
        emotions. However, over the last several decades it has been found to be
        very helpful in treating a wide range of struggles when difficulty
        managing emotions is involved. DBT is a sophisticated treatment that
        guides therapists to be savvy and grounded in the treatment they
        provide, and offers very concrete skills to patients in order to help
        them manage emotions in a way that is conducive to building a life worth
        living. The idea is that living a life dictated by a rollercoaster of
        emotions often does not feel very worthwhile. However, learning to
        modulate your emotions, approach them with curiosity and openness, and
        act effectively with them (and sometimes despite them), can lead to less
        misery and more meaning.
        <br />
        <br />
        DBT, practiced per protocol, includes four components:{" "}
        <Lister
          items={[
            `Individual therapy: This is where you will work to apply
        skills to specific situations in your life in which you struggle in
        order to decrease behaviors that are life threatening (suicidality and
        self-harm for example), and increase behaviors conducive to improvement
        in quality of life.`,
            `Skills group/class: This is where you will learn
        skills to understand and manage your emotions.`,
            `Phone Coaching in-between sessions: It is a lot to expect that from the start you will know how to
        apply the DBT skills to difficult situations if you are only spending a
        limited amount of time with your therapist and in group. So, phone
        coaching is an opportunity for you to reach out to your therapist
        between sessions in difficult situations when you’re not sure what
        skills to apply. In this way, the skills you learn in group and in
        session will generalize to your life outside of therapy.`,
            `Consultation Team for the therapist: DBT is a comprehensive therapy and therapists
        need support and consultation in order to provide the best care for you.
        DBT requires that therapists be part of a structured consultation team
        to ensure they are delivering the treatment as intended in DBT.`,
          ]}
        />
      </Section>
    </div>
  );
};
