import React from "react";
import { Section } from "src/Components/Common/Section";

export const ConsultingLanding = () => {
  return (
    <div className={"ConsultingLanding"}>
      <div className={"content"}>
        <Section title={"Professionals"} sectionSubtype={"dramatic"}>
          We are available for clinical consultation to other mental health
          professionals on a one-time or ongoing basis. Our specializations
          include anxiety and related disorders, eating disorders, and DBT,
          including problems with emotion dysregulation, self-harm, and suicide
          behaviors).
          <br />
          <br /> Therapists in the community have typically sought our
          consultation to assist in clarifying clinical diagnoses, generating
          ideas about appropriate types and levels of care, and making ongoing
          treatment plans.
        </Section>
        <Section title={"Parents & Families"} sectionSubtype={"dramatic"}>
          We recognize that times in which your child struggles are difficult
          and sometimes terrifying. Consultations can take the form of just one
          or a handful of sessions or they can be ongoing support. We will
          review the child and family history, discuss possible treatment needs,
          and provide guidance on effective ways to respond to your child.
        </Section>
        <Section title={"Patients"} sectionSubtype={"dramatic"}>
          Sometimes it can be difficult to pinpoint what help you need or what
          treatment might be most indicated for the issue with which you're
          struggling. We provide one-time assessments and consultation to help
          identify driving forces and what challenges lie at the core of things.
          We can provide recommendations for the most effective treatments to
          address your needs, or transition to that treatment with our team.
        </Section>
      </div>
    </div>
  );
};
