import React from "react";
import { Section } from "src/Components/Common/Section";

export const Policies = () => {
  return (
    <div className={"ConnectingLanding"}>
      <div className={"content"}>
        <div style={{ marginBottom: "24px" }}>
          The cost schedule is based on service and severity needs. We are
          always happy to discuss - (617) 286-4919.
        </div>
        <Section title={"Insurance"} sectionSubtype={"dramatic bufferBottom"}>
          We do not accept insurance and are considered out of network. Invoices
          are usually sent out at the beginning of each month. We will provide
          statements for insurance reimbursement if you are eligible for out of
          network services through your insurance provider.
        </Section>
        <Section title={"Payment Methods"} sectionSubtype={"dramatic"}>
          Many people prefer to pay by check. Credit card processing is also
          available.
        </Section>
        <Section
          title={"Cancellation Policy"}
          sectionSubtype={"dramatic bufferBottom"}
        >
          At least 24 hours notice is needed for a cancellation. Otherwise full
          payment for the session will be due.
        </Section>
      </div>
    </div>
  );
};
