import React from "react";

export const Section = ({
  title,
  children,
  subheading,
  sectionSubtype,
  headerImage,
}: {
  title: string;
  children: React.ReactNode;
  subheading?: React.ReactNode;
  sectionSubtype?: string;
  headerImage?: React.ReactNode;
}) => {
  return (
    <div className={`Section ${sectionSubtype}`}>
      <div className={"heading"}>{title}</div>
      <div className={`body ${!!headerImage ? "withHeaderImage" : ""}`}>
        {!!subheading && <div className="subheading">{subheading}</div>}
        <div className={"imageBuffer"}> </div>
        {!!headerImage && (
          <div className={"headerImageHolder"}>{headerImage}</div>
        )}
        {children}
      </div>
    </div>
  );
};
