import React from "react";
import { Section } from "src/Components/Common/Section";

export const RODBT = () => {
  return (
    <div className={"content"}>
      <Section title={"Radically-Open DBT (RO-DBT)"}>
        Whereas DBT is aimed at helping people to have difficulty managing their
        emotions, RO-DBT is a treatment aimed at helping those who “over-manage”
        their emotions such that it gets in the way of relationships and feeling
        connected to the world. Often people who are “over-controled” or OC, see
        emotions as an inconvenience and due to heightened abilities to control
        their attention and internal reactions, are adept at shutting down
        emotions and prioritizing logic as a way of navigating the world. As
        much as emotions are inconvenient, however, they actually are important
        from an evolutionary perspective, and they help inform us of what’s
        important to us and help us form connections and communicate with
        others. When we shut down our emotions, we often lose touch with what is
        important to us and we feel lonely and isolated. RO DBT is a treatment
        aimed at helping people learn to “chill out”, discover the value in
        trying new things, and learn to act in ways that promote social
        connection rather than isolation. RO-DBT includes both individual
        sessions and a weekly skills class.{" "}
      </Section>
    </div>
  );
};
