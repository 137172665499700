import React from "react";
import { Section } from "src/Components/Common/Section";

export const ACT = () => {
  return (
    <div className={"content"}>
      <Section title={"Acceptance & Commitment Therapy (ACT)"}>
        ACT is a treatment that focuses on working with people to act in ways
        that are aligned with their values and what is important to them, even
        in the face of discomfort and pain. The idea is that if we can accept
        what we have no control over, the fact that life includes pain and that
        we may get uncomfortable, and approach pain and struggle in a more
        flexible way, we can then have space to broaden our lives and fill our
        lives with more meaning and purpose. ACT, as its name suggests, focuses
        on finding new ways to take action in order to broaden and deepen our
        lives. To this end, ACT makes use of exposure therapy and behavior
        activation, as well as mindfulness and acceptance work.
      </Section>
    </div>
  );
};
