import React from "react";
import twitterlogo from "src/assets/twitter-dark.png";
import emailicon from "src/assets/email.png";
import phoneicon from "src/assets/phone.png";

export const MainFooter = () => {
  return (
    <div className={"MainFooter"}>
      <div className="list">
        <div className="item">
          <a href="mailto:info@bostonevidencesupportedtreatments.com">
            info @ BEST
          </a>
        </div>
        <div className="item icons">
          <a href="https://twitter.com/lyviachriki" target="_blank">
            <img src={twitterlogo} alt="twitter" />
          </a>
          <a href="mailto:info@bostonevidencesupportedtreatments.com">
            <img src={emailicon} alt="email" className="large" />
          </a>
          <a href="tel:8572341533">
            <img src={phoneicon} alt="call" style={{ width: "17px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};
