import React from "react";
import { Section } from "../Common/Section";
import { ConnectingLink } from "./Connecting/ConnectingLink";

export const HomePage = () => {
  return (
    <div className="HomePage">
      Coming out of a week of strong training feels so energizing. We love
      coming into sessions with more knowledge, armed with new skills to help
      our patients heal or an alternative vantage point to see their struggles.
      Sometimes it's as small as having that one new line we picked up and
      watching how powerful it can be, but even better is a week of new
      techniques in a subject that was outside our comfort zone before.
      <br />
      <br />
      Training is how we stay sharp and up-to-date on the science of mental and
      emotional health. We're thrilled to offer new avenues for that growth to
      the field.
      <br />
      <br />
      <Section
        title={"Who We Treat"}
        subheading={"The Good, The Bad, and The Ugly"}
        sectionSubtype={"bufferBottom"}
      >
        <div style={{ margin: "20px 0" }}>
          The BEST practice offers many different therapies for adults and
          teens, as well as parents and couples. USP also has clinicians who
          focus on Women’s Mental Health and the LGBTQ Community.
        </div>
      </Section>
      <div
        style={{
          display: "grid",
          gridGap: "40px",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <img src="https://i.pinimg.com/originals/eb/9c/ce/eb9cce5d356f5ead2ec83e3849bbea01.jpg" />
        <img src="https://i.pinimg.com/originals/8e/d9/f2/8ed9f22b81076d94300f3f7293662c55.jpg" />
        {/*<img src="https://previews.123rf.com/images/burakowski/burakowski1302/burakowski130200037/18135419-bright-splash-grunge-design.jpg" />*/}
      </div>
    </div>
  );
};
