import React, { useEffect, useState } from "react";
import { useClientNode, useClientRect } from "src/utils/hooks";

function getPagePos(el: any) {
  let lx = 0;
  let ly = 0;
  for (
    ;
    el != null;
    lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
  ) {}
  return { x: lx, y: ly }!;
}

export const HoverDrop = ({
  mainDisplay,
  insideDrop,
  classname,
  goRight,
}: {
  mainDisplay: React.ReactNode;
  insideDrop: React.ReactNode;
  classname?: string;
  goRight?: boolean;
}) => {
  let [anchorNode, anchorRef] = useClientNode();
  let [dropNode, dropRef] = useClientNode();

  const [inHover, setInHover] = useState(false);
  const [styleLoc, setStyleLoc] = useState<{
    left?: string;
    right?: string;
    top: string;
  }>({ left: "-200px", top: "-200px" });

  useEffect(() => {
    setTimeout(() => {
      if (anchorNode) {
        const anchorBoundingRect = (anchorNode as any).getBoundingClientRect() as ClientRect;
        const dropBoundingRect = (dropNode as any).getBoundingClientRect() as ClientRect;
        const pageOffsets = getPagePos(anchorNode);

        const anchorLocTop = pageOffsets.y + anchorBoundingRect.top;
        const anchorLocLeft = pageOffsets.x;

        const screenWidth = Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
        );

        if (goRight || anchorLocLeft + dropBoundingRect.width >= screenWidth) {
          setStyleLoc({
            right: `${
              screenWidth - (anchorLocLeft + anchorBoundingRect.width)
            }px`,
            top: `${anchorLocTop}px`,
          });
        } else {
          setStyleLoc({
            left: `${anchorLocLeft}px`,
            top: `${anchorLocTop}px`,
          });
        }
      }
    }, 1000);
  }, [anchorNode]);

  return (
    <div
      ref={anchorRef}
      className={`hoverDrop ${classname}`}
      onMouseEnter={() => setInHover(true)}
      onMouseLeave={() => setInHover(false)}
    >
      <div className={"mainDisplay"}>{mainDisplay}</div>
      <div
        ref={dropRef}
        className={`insideDrop ${inHover ? "shown" : "hidden"} ${
          styleLoc.right ? "rightHug" : ""
        }`}
        style={inHover ? styleLoc : undefined}
      >
        <div className={"insideRuler"}>{insideDrop}</div>
      </div>
    </div>
  );
};
