import React from "react";
import { Section } from "src/Components/Common/Section";

export const PEforPTSD = () => {
  return (
    <div className={"content"}>
      <Section title={"Prolonged Exposure (PE) for PTSD"}>
        When people go through a traumatic event, they often, understandably,
        avoid memories and reminders of the event. While at first the avoidance
        is restricted to a few memories and situations, over time people find
        that they are spending much of their time trying to suppress thoughts,
        avoid reminders, and push down emotions of sadness and fear. PE focuses
        on having people face the feared memories and situations in a safe and
        supportive environment in order to reduce symptoms of PTSD. There are
        two forms of exposure: In imaginal exposure, individuals recounter the
        traumatic memory to the therapist in session by imagining the situation
        and describing it as it occurred. In In-vivo exposure, people approach
        situations in their current life that they have been avoiding due to it
        triggering discomfort, anxiety, or difficult memories. This is a lot of
        work, and people often struggle at first, however PE shows very
        effective results and people often notice a reduction in their symptoms
        and the ability to to put the past in the past and live more freely in
        the present.
      </Section>
    </div>
  );
};
