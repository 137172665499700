import React from "react";

export const Lister = ({ items }: { items: (string | React.ReactNode)[] }) => {
  return (
    <ul className={`Lister`}>
      {items.map((item) => (
        <li>{item}</li>
      ))}
    </ul>
  );
};
