import React from "react";
import { Section } from "src/Components/Common/Section";

export const DBTPTSD = () => {
  return (
    <div className={"content"}>
      <Section title={"DBT-PTSD"}>
        Similar to DBT-PE, DBT-PTSD makes use of imaginal exposure to help
        people stop feeling that the memories of the past are haunting them.
        This treatment is slightly different in other elements that are
        incorporated and borrows from ACT, compassion focused therapy, and
        elements of other treatments to bolster and supplement exposure work.
        Individuals choosing to do DBT-PTSD do not have to first engage in the
        DBT program and learn skills with their individual therapist as part of
        the treatment.
      </Section>
    </div>
  );
};
