import React from "react";
import { Section } from "src/Components/Common/Section";

export const DBTPE = () => {
  return (
    <div className={"content"}>
      <Section title={"DBT for Prolonged Exposure (PE)"}>
        DBT-PE is a treatment that incorporates both PE and DBT to help patients
        build a life worth living. After individuals are no longer actively
        engaging in life-threatening behaviors and are in the stage of DBT aimed
        at building a better quality of life, they may identify that PTSD is
        getting in the way. In this case, PE can be incorporated into treatment
        in a way that takes into account what the patient needs from a DBT
        perspective in order to make use of therapy.
      </Section>
    </div>
  );
};
