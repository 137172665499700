import React from "react";
import { Section } from "src/Components/Common/Section";

export const DBTA = () => {
  return (
    <div className={"content"}>
      <Section title={"DBT for adolescents and families (DBT-A)"}>
        Both in DBT and DBT-A, a concept that is important is that people do not
        grow up in vacuums. They grow up with others around them in
        relationships that are transactional -- meaning, the dynamics and
        influences in the relationships are bi-directional. Especially when
        providing DBT to teens, it is important to involve the family, as teens
        are still living with their families. Therefore, at our clinic, we
        provide multi-family groups in which both the teen and caregiver are
        present, as well as family work based on DBT principles.
      </Section>
    </div>
  );
};
