import React from "react";
import { MainFooter } from "./MainFooter";
import { Section } from "./Common/Section";
import { ConnectingLanding } from "./Features/Connecting/ConnectingLanding";
import { TreatmentLanding } from "./Features/Treatment/TreatmentLanding,";
import { About } from "./Features/About/About";
import { Team } from "./Features/About/Team";
import { Switch, Route, Redirect } from "react-router-dom";
import { FourOhFour } from "./FourOhFour";
import { HomePage } from "./Features/HomePage";
import { OtherPage } from "./Features/OtherPage";
import { CBT } from "./Features/Treatment/CBT";
import { ACT } from "./Features/Treatment/ACT";
import { ExposureTherapy } from "./Features/Treatment/ExposureTherapy";
import { CPT } from "./Features/Treatment/CPT";
import { DBTPE } from "./Features/Treatment/DBTPE";
import { DBTPTSD } from "./Features/Treatment/DBTPTSD";
import { DBT } from "./Features/Treatment/DBT";
import { RODBT } from "./Features/Treatment/RODBT";
import { ParentGuidance } from "./Features/Treatment/ParentGuidance";
import { ConsultingLanding } from "./Features/Consultation/ConsultationLanding";
import { DBTA } from "./Features/Treatment/DBTA";
import { PEforPTSD } from "./Features/Treatment/PEforPTSD";
import { Policies } from "./Features/NewClients/Policies";

export const MainContent = () => {
  return (
    <div className={"MainContent"}>
      <div className={"content"}>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/about/team/">
            <Team />
          </Route>
          <Route path="/about/">
            <About />
          </Route>
          <Route path="/treatment/act/">
            <ACT />
          </Route>
          <Route path="/treatment/cbt/">
            <CBT />
          </Route>
          <Route path="/treatment/dbt/">
            <DBT />
          </Route>
          <Route path="/treatment/dbt-a/">
            <DBTA />
          </Route>
          <Route path="/treatment/exposure/">
            <ExposureTherapy />
          </Route>
          <Route path="/treatment/ro-dbt/">
            <RODBT />
          </Route>
          <Route path="/treatment/parent-guidance/">
            <ParentGuidance />
          </Route>
          <Route path="/treatment/trauma/cpt/">
            <CPT />
          </Route>
          <Route path="/treatment/trauma/peptsd">
            <PEforPTSD />
          </Route>
          <Route path="/treatment/trauma/dbtpe/">
            <DBTPE />
          </Route>
          <Route path="/treatment/trauma/dbtptsd/">
            <DBTPTSD />
          </Route>
          <Route path="/treatment/">
            <TreatmentLanding />
          </Route>

          <Route path="/consultation/">
            <ConsultingLanding />
          </Route>

          <Route path="/getting-started/policies/">
            <Policies />
          </Route>
          <Route path="/getting-started/">
            We value collaboration with other clinics and providers. If you or
            your child has providers outside of our center, we will do all that
            we can to establish a collaborative relationship with them by
            sharing information (with your permission, of course) and aligning
            with them as a team.
          </Route>
          <Route path="/contact/">
            <ConnectingLanding />
          </Route>
          <Route path="/gobble/">
            <OtherPage />
          </Route>
          <Route path="*">
            <FourOhFour />
          </Route>
        </Switch>
      </div>
      <MainFooter />
    </div>
  );
};
