import React, { useState, useCallback } from "react";

export function useClientRect() {
  const [rect, setRect] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  return [rect, ref];
}

export function useClientNode() {
  const [node, setNode] = useState(null);

  const ref = useCallback((nodeRef) => {
    if (nodeRef !== null) {
      setNode(nodeRef);
    }
  }, []);

  return [node, ref];
}
