import React from "react";
import { Section } from "src/Components/Common/Section";
import { ConnectingLink } from "../Connecting/ConnectingLink";

export const About = () => {
  return (
    <div className={"About"}>
      <Section title={"A Home Base. A Sounding Board."}>
        When you’re struggling, whether it is to rebuild your life, figure out
        your direction going forward, helping a loved one, or just working on
        gaining some clarity, having a stable and welcoming place to come to can
        be invaluable. We hope to create this for you by figuring out with you
        what you or your family need and being thoughtful and consistent with
        the care we provide.
      </Section>
      <Section title={"Scientists"}>
        If we are here to be healers, our top priority needs to be treatments
        that work. That means our practice is guided by research and current
        science.
        <br />
        <br />
        Over the last five decades, evidence based treatments have developed and
        evolved. Today, even issues that were once thought as very difficult to
        treat, such as Borderline Personality Disorder, Substance Use Disorders,
        OCD, and Psychosis, to name a few, have effective treatments grounded in
        science and research, that help individuals find relief from suffering
        and build lives of meaning.
      </Section>
    </div>
  );
};
