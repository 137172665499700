import React from "react";
import { Section } from "src/Components/Common/Section";

export const ExposureTherapy = () => {
  return (
    <div className={"content"}>
      <Section
        title={
          "Exposure Therapy and Behavior Activation for Anxiety and Depression"
        }
      >
        Although the name sounds a little scary, exposure therapy basically
        means learning through experience. Research suggests that although human
        beings can learn new behaviors by talking through our thought processes,
        people achieve the most change and learning through new behaviors. In
        exposure work, we will identify ways in which avoidance has held you
        back or led to a pretty constricted life and then work on helping you
        approach the situations you’ve been avoiding so as to broaden your
        perspective and ultimately build a more fulfilling and meaningful life.
        This is collaborative work, meaning we will not bully or push you into
        something you are not on board with and meet you where you’re at.
      </Section>
    </div>
  );
};
