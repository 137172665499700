import React from "react";
import { Section } from "src/Components/Common/Section";

export const ParentGuidance = () => {
  return (
    <div className={"content"}>
      <Section title={"Parent Guidance and Consultation"}>
        Having your child struggle can be very scary and also can be a lonely
        process. We offer support and guidance for parents of teens and young
        adults who are struggling with issues of anxiety and emotion
        dysregulation. Guidance is based on principles of behaviorism and DBT
        and we will help you learn to balance accepting where your child is at
        and the limits to your control with setting effective limits with your
        child to help them learn and grow. In the process, we work to help you
        identify your own values as a parent and have these values guide your
        responses to your child.
      </Section>
    </div>
  );
};
